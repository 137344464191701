<template>
	<div class="materialPage">
		<formRightHeader :isMaterial='true' :navName="$t('material')"></formRightHeader>
		<div class="tool-bar">
			<div class="add-product-btn pointer-btn" @click.stop="addMaterial">
			  <i class="iconfont icon-ic_add add-product-icon"></i>
			  <span>{{$t('addBtn')}}</span>
			</div>
			<div v-click-outside="checkOutSide">
			  <div class="form-search-box" v-show="!show" @click="showSearch">
			    <i class="iconfont icon-sousuo1"></i>
			    <span>{{ $t("searchForm") }}</span>
			  </div>
			  <div class="form-search-box" v-show="show">
			    <i class="iconfont icon-sousuo1"></i>
			    <input
			      ref="input"
			      type="text"
			      class="search-input"
			      placeholder="输入材料名称"
			      v-model="searchName"
			      @keydown.enter="searchSn"
			      @blur="searchSn"
			    />
			  </div>
			</div>
		</div>
		<div class="n-table">
			<a-table
			style="min-width: 1100px;"
			 size="small"
			  rowKey="id"
			  :scroll="{ y: 600 }"
			 :columns="columns"
			 :data-source="lists"
			 :pagination="pagination"
			 @change="handleTableChange"
			>
				<template v-for="(col, idx) in columns" v-if="col.slots" :slot='col.slots.title'>
					<column-title title="供应商" type="search">
						<search-list :option="col.slots"/>
					</column-title>
				</template>
			</a-table>
		</div>
		<el-dialog
			:visible.sync="dialogVisible"
			width="1100px"
		>
			<div class="">
				<div class="infoBox" style="width: calc(20% - 10px);margin-right: 10px;">
					<el-upload
					  v-if="isEdit"
					  v-loading="loading"
					  class="avatar-uploader"
					  accept=".jpg,.jpeg,.png,.JPG,.PNG"
					  action=""
					  :show-file-list="false"
					  :http-request="picUpload"
					  :before-upload="beforeAvatarUpload">
					  <img v-if="form.material_url" :src="form.material_url" style="width: 100%;">
					  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<img v-else width="100%" :src="form.material_url">
					<vue-qr v-if="form.id && !isEdit" style="margin-top: 20px;" :text="preHref" :size="150"></vue-qr>
					<span v-if="form.id && !isEdit" style="cursor: pointer;" @click="clickCopy(preHref)">
					  点击复制二维码链接
					  <i class="el-icon-document-copy"/>
					</span>
				</div>
				<div class="infoBox" style="width: 80%;">
					<el-form size="small" label-width="100px" label-position='left' class="infoBox" style="width: 50%;">
						<el-form-item label="供应商名称:">
						    <el-select style="width: 100%;" v-if="isEdit" v-model="form.supplier_id" @change="selectChange()" placeholder="请选择供应商" filterable default-first-option>
						      <el-option v-for="(item,index) in supplierList" :label="item.supplier_name" :value="item.id"></el-option>
						    </el-select>
							<label v-else >{{form.supplier_name}}</label>
						</el-form-item>
						<el-form-item label="材料类型:">
							<el-input v-if="isEdit" v-model="form.material_type"></el-input>
							<label v-else >{{form.material_type}}</label>
						</el-form-item>
						<el-form-item label="联系人:">
							<el-input v-if="isEdit" v-model="form.contact_name" disabled></el-input>
							<label v-else >{{form.contact_name}}</label>
						</el-form-item>
						<el-form-item label="联系方式:">
							<el-input v-if="isEdit" v-model="form.contact" disabled></el-input>
							<label v-else >{{form.contact}}</label>
						</el-form-item>
						<el-form-item label="地址:">
							<el-input v-if="isEdit" v-model="form.address" disabled></el-input>
							<label v-else >{{form.address}}</label>
						</el-form-item>
						<el-form-item label="付款方式:">
							<el-input v-if="isEdit" v-model="form.pay_type"></el-input>
							<label v-else >{{form.pay_type}}</label>
						</el-form-item>
						<el-form-item label="是否含运费:">
							<el-input v-if="isEdit" v-model="form.is_express"></el-input>
							<label v-else >{{form.is_express}}</label>
						</el-form-item>
						<el-form-item label="开户行:">
							<el-input v-if="isEdit" v-model="form.bank" disabled></el-input>
							<label v-else >{{form.bank}}</label>
						</el-form-item>
						<el-form-item label="存放位置:">
							<el-input v-if="isEdit" v-model="form.location"></el-input>
							<label v-else >{{form.location}}</label>
						</el-form-item>
						<el-form-item label="备注:">
							<el-input v-if="isEdit" type="textarea" v-model="form.note"></el-input>
							<label v-else >{{form.note}}</label>
						</el-form-item>
					</el-form>
					<el-form :model="form"  :rules="rules" label-position='left' ref="ruleForm" size="small" label-width="100px" class="infoBox" style="width: 50%;">
						<el-form-item label="材料名称:" prop="material_name">
							<el-input v-if="isEdit" v-model="form.material_name" @change="changeMaterialName"></el-input>
							<label v-else >{{form.material_name}}</label>
						</el-form-item>
						<el-form-item label="材料来源:">
							<el-input v-if="isEdit" v-model="form.source"></el-input>
							<label v-else >{{form.source}}</label>
						</el-form-item>
						<el-form-item v-if="typeArr.indexOf(form.material_type) != -1" label="所属国家尺码:">
							<el-input v-if="isEdit" v-model="form.country"></el-input>
							<label v-else>{{form.country}}</label>
						</el-form-item>
						<el-form-item v-if="typeArr.indexOf(form.material_type) != -1" label="码差:">
							<el-select style="width: 100%;" v-if="isEdit" v-model="form.code" placeholder="请选择码差" filterable default-first-option>
							  <el-option v-for="(item,index) in codeList" :label="item.label" :value="item.value"></el-option>
							</el-select>
							<label v-else>{{form.code}}</label>
						</el-form-item>
						<el-form-item v-if="typeArr.indexOf(form.material_type) != -1" label="码段:">
							<el-cascader
								v-if="isEdit"
								ref="cascader"
								style="width: 100%;"
							    v-model="form.size"
							    :options="options"
							    :props="{ expandTrigger: 'hover' }"
							    @change="handleChange">
							</el-cascader>
							<label v-else>{{form.size[0]}} {{form.size[1]}}</label>
						</el-form-item>
						<el-form-item label="规格:">
							<el-input v-if="isEdit" v-model="form.spec"></el-input>
							<label v-else >{{form.spec}}</label>
						</el-form-item>
						<el-form-item label="单位:">
							<el-input v-if="isEdit" v-model="form.unit"></el-input>
							<label v-else >{{form.unit}}</label>
						</el-form-item>
						<el-form-item label="单价:">
							<el-input v-if="isEdit" v-model="form.price"></el-input>
							<label v-else >{{form.price}}</label>
						</el-form-item>
						<el-form-item label="颜色:">
							<el-input v-if="isEdit" v-model="form.color"></el-input>
							<label v-else >{{form.color}}</label>
						</el-form-item>
						<el-form-item label="数量:" v-if="typeArr.indexOf(form.material_type) == -1">
							<el-input v-if="isEdit" v-model="form.num"></el-input>
							<label v-else >{{form.num}}</label>
						</el-form-item>
						<el-form-item label="入库日期:" >
							<el-date-picker
							      style="width: 100%;"
								  v-if="isEdit"
							      v-model="form.warehouse_date"
							      align="right"
							      type="date"
							      placeholder="选择日期"
								  @change="changeDate()">
							</el-date-picker>
							<label v-else >{{form.warehouse_date}}</label>
						</el-form-item>
					</el-form>
					<div v-if="typeArr.indexOf(form.material_type) != -1 && form.size.length == 2 && form.size_num.length > 0" class="tableBox">
						<div class="tableRow">
							<div class="row" style="width: 40px;">尺码</div>
							<div class="row" style="width: 40px;">数量</div>
						</div>
						<div class="tableRow" v-for="(item,index) in this.form.size_num">
							<div class="row">{{item.label}}</div>
							<div class="row">
								<el-input type="number" v-if="isEdit" min='0' v-model="item.num"></el-input>
								<label v-else>{{item.num}}</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
			    <el-button @click="dialogVisible = false">取 消</el-button>
				<div class="footerBox">
					<el-button type="primary" @click="editData" :loading="isLoading" v-if="isEdit && !isAdd">保存</el-button>
					<el-button type="primary" @click="saveData" :loading="isLoading" v-if="isAdd">保存</el-button>
				</div>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import formRightHeader from "@/components/lists/formRightHeader";
import uploadCos from "@/utils/upload_to_cos";
import ColumnTitle from "../table/column-title";
import SearchList from "../table/search-list";
import api from "@/api";
import vueQr from 'vue-qr';
import size from "../../../utils/size.js";
export default {
	components:{
		formRightHeader,
		ColumnTitle,
		SearchList,
		vueQr,
	},
	data(){
		return {
			dialogVisible:false,
			isEdit:false,
			isAdd:false,
			form:{},
			lists:[],
			supplierList:[],
			loading:false,
			isLoading:false,
			searchName:'',
			supplier_id:'',
			show:false,
			preHref:'',
			options:[],
			typeArr:['楦头','大底','中底','鞋垫'],
			pagination: {
			  current: 1,
			  pageSize: 10,
			  total: 0,
			},
			columns:[
				{
				  title: '图片',
				  key:'material_url',
				  customRender:(text,row)=>{
					  if(row.material_url){
						  return(<el-image style="width: 50px;" src={row.material_url} preview-src-list={[row.material_url]}></el-image>);
					  }
				  }
				},
				{
				  title:'材料名称',
				  dataIndex: "material_name",
				  key:'material_name',
				  customRender:(text,row)=>{
				  	return(<a-tooltip placement="topLeft" title={text}><span style="cursor: pointer;" onClick={(e) => this.clickCopy(text)} class="col-sql" title={text}>{text}<i class="el-icon-document-copy"/></span></a-tooltip>);
				  }
				},
				{
				  dataIndex: "supplier_name",
				  key:'supplier_name',
				  customRender:(text,row)=>{
				  	return(<a-tooltip placement="topLeft" title={text}><span class="col-sql" title={text}>{text}</span></a-tooltip>);
				  },
				  slots:{
					title:'supplierName',
					type:'search',
					ok:(e) => {
						this.supplier_id = e.id;
						this.getMaterialList()
					},
					api:api.getSupplierSelectList
				  }
				},
				{
				  title: '入库日期',
				  dataIndex: "warehouse_date",
				  key:'warehouse_date',
				},
				{
				  title: '数量',
				  dataIndex: "num",
				  key:'num',
				  width:'50px'
				},
				{
				  title: '存放位置',
				  dataIndex: "location",
				  key:'location',
				  width:'120px',
				  customRender:(text,row)=>{
				  	return(<a-tooltip placement="topLeft" title={text}><span class="col-sql" title={text}>{text}</span></a-tooltip>);
				  }
				},
				{
					title:'操作',
					key:'action',
					width:'300px',
					customRender:(text,row)=>{
						return(<div>
							<div class="handleClass" onClick={(e) => this.handleDetail(e,row)}>详情</div>
							<div class="handleClass" onClick={(e) => this.handleEdit(e,row)}>编辑</div>
							<div class="handleClass" onClick={(e) => this.handleCopy(e,row)}>复制</div>
							<div class="handleClass" onClick={(e) => this.handleCopyQr(row)}>二维码<i class="el-icon-document-copy"/></div>
							<div class="handleClass handleDelete" onClick={(e) => this.handleDelete(e,row)}>删除</div>
						</div>)
					}
				}
			],
			rules: {
			  material_name: [
				{ required: true, message: '请输入材料名称', trigger: 'blur' },
			  ],
			},
			//码差
			codeList:[
				{
					value:'欧码6.66mm',
					label:'欧码6.66mm'
				},
				{
					value:'美码8.46mm',
					label:'美码8.46mm'
				},
				{
					value:'美码8mm',
					label:'美码8mm'
				},
				{
					value:'日码10mm',
					label:'日码10mm'
				},
				{
					value:'中码5mm',
					label:'中码5mm'
				},
			]
		}
	},
	watch:{
		form:{
			handler(){
				
			},
			deep:true
		}
	},
	created(){
		this.form = this.formData();
		this.getSupplierSelectList();
		this.getMaterialList();
		this.options = size;
	},
	methods:{
		beforeAvatarUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < 2;
	
			if (!isLt2M) {
			  this.$message.error('上传头像图片大小不能超过 2MB!');
			}
			return isLt2M;
		},
		//上传图片
		picUpload(file) {
		  let that = this;
		  that.loading = true;
		  uploadCos([file.file], "works", (picture) => {
			   that.form.material_url = picture[0]
			   that.loading = false;
		  });
		},
		//重置参数
		formData(){
			return{
				material_name:'',
				material_type:'',
				material_url:'',
				supplier_id:'',
				spec:'',
				unit:'',
				price:'',
				num:'',
				color:'',
				warehouse_date:'',
				location:'',
				pay_type:'',
				is_express:'',
				note:'',
				supplier_name:'',
				contact_name:'',
				contact:'',
				address:'',
				bank:'',
				source:'',
				size:[],
				code:'',
				country:'',
				size_num:[]
			}
		},
		//普通材料部分数据清理
		resetData(){
			if(this.typeArr.indexOf(this.form.material_type) == -1){
				this.form.code = '';
				this.form.country = '';
				this.form.size = [];
				this.form.size_num = [];
			}else{
				let that = this;
				that.form.num = 0;
				this.form.size_num.forEach(function(item){
					if(item.num){
						that.form.num += parseInt(item.num);
					}
				})
			}
		},
		//获取供应商
		getSupplierSelectList(){
			this.$api.getSupplierSelectList({supplier_name:''}).then((data)=>{
				this.supplierList = data;
			})
		},
		//获取材料
		getMaterialList(){
			let loading = this.openLoading();
			let data = {
				material_name:this.searchName,
				supplier_id:this.supplier_id,
				page:this.pagination.current,
				page_size:this.pagination.pageSize
			}
			this.$api.getMaterialListData(data).then((res)=>{
				loading.close();
				this.lists = res.data;
				this.pagination.total = res.total
			})
		},
		//分页
		handleTableChange(pagination) {
		  this.pagination.current = pagination.current;
		  this.getMaterialList();
		},
		//保存
		saveData(){
			return new Promise((resolve)=>{
				this.$refs['ruleForm'].validate((valid) => {
				  if (valid) {
					this.isLoading = true;
					this.resetData();
					this.$api.createMaterial(this.form).then((res)=>{
						this.isLoading = false;
						if(res.status == 1){
							this.dialogVisible = false;
							this.$message.success(res.msg)
							this.getMaterialList();
						}else{
							this.$message.error(res.msg)
						}
						resolve(res);
					})
				  } else {
					this.$message.error('必填项未填')
					return false;
				  }
				});
			})
		},
		//编辑
		editData(){
			return new Promise((resolve)=>{
				this.isLoading = true;
				this.$refs['ruleForm'].validate((valid) => {
				  if (valid) {
					this.resetData();
					this.$api.updateMaterialData(this.form).then((res)=>{
						this.isLoading = false;
						if(res.status == 1){
							this.dialogVisible = false;
							this.$message.success(res.msg)
							this.getMaterialList();
						}else{
							this.$message.error(res.msg)
						}
						resolve(res);
					})
				  } else {
					this.$message.error('必填项未填')
					return false;
				  }
				});
			})
		},
		//添加材料
		addMaterial(){
			this.dialogVisible = true;
			this.isEdit = true;
			this.isAdd = true;
			this.isLoading = false;
			this.form = this.formData();
		},
		//复制
		handleCopy(e,row){
			this.dialogVisible = true;
			this.isEdit = true;
			this.isAdd = true;
			this.isLoading = false;
			this.form = row;
		},
		//删除
		handleDelete(e,row){
			this.$confirm('确认删除？')
			  .then(_ => {
				this.$api.delMaterialData({id:row.id}).then((res)=>{
					if(res.status == 1){
						this.$message.success(res.msg);
						this.getMaterialList();
					}else{
						this.$message.error(res.msg)
					}
				})
			  })
			  .catch(_ => {});
			
		},
		//编辑
		handleEdit(e,row){
			this.dialogVisible = true;
			this.isEdit = true;
			this.isAdd = false;
			this.isLoading = false;
			this.form = row;
		},
		//详情
		handleDetail(e,row){
			this.dialogVisible = true;
			this.isEdit = false;
			this.isAdd = false;
			this.form = row;
			this.preHref = window.location.origin+'/#/materialDetail?id='+row.codeStr;
			//this.$refs['ruleForm'].resetFields();
		},
		//复制二维码
		handleCopyQr(row){
			this.clickCopy(window.location.origin+'/#/materialDetail?id='+row.codeStr);
		},
		//选择供应商
		selectChange(){
			for(let item of this.supplierList){
				if(item.id == this.form.supplier_id){
					this.form.address = item.address
					this.form.bank = item.bank
					this.form.contact = item.contact
					this.form.contact_name = item.contact_name
					this.form.is_express = item.is_express
					this.form.material_type = item.material_type
					this.form.pay_type = item.pay_type
				}
			}
			if(!this.form.supplier_id){
				this.form.address = ''
				this.form.bank = ''
				this.form.contact = ''
				this.form.contact_name = ''
				this.form.is_express = ''
				this.form.material_type = ''
				this.form.pay_type = ''
			}
		},
		//改变日期
		changeDate(){
			this.form.warehouse_date = this.form.warehouse_date?this.form.warehouse_date.toLocaleDateString():'';
		},
		//显示搜索框
		showSearch() {
		  this.show = true;
		  this.$nextTick(() => {
		    this.$refs.input.focus();
		  });
		},
		checkOutSide() {
		 if (this.searchName) {
		    this.show = true;
		  } else {
		    this.show = false;
		  }
		},
		//搜索
		searchSn() {
		  if (this.searchName) {
		    this.pagination.current = 1;
		  }
		  this.getMaterialList();
		},
		//码段选项框改变
		handleChange(){
			let nodesInfo = this.$refs['cascader'].getCheckedNodes()[0];
			this.form.size_num = nodesInfo.data.childrens;
		},
		//材料名称改变
		changeMaterialName(){
			
		},
		//复制到剪切板
		clickCopy(msg) {
		  const save = function(e) {
		    e.clipboardData.setData('text/plain', msg)
		    e.preventDefault() // 阻止默认行为
		  }
		  document.addEventListener('copy', save) // 添加一个copy事件
		  document.execCommand('copy') // 执行copy方法
		  this.$message({ message: '复制成功', type: 'success' })
		}
	}
}
</script>

<style lang="scss">
.materialPage{
	width: 100%;
	
	.infoBox{
		display: inline-block;
		padding: 10px 20px;
		vertical-align: top;
	}
	
	.handleClass{
		cursor: pointer;
		display: inline-block;
		margin-right: 10px;
	}
	.handleDelete{
		color: red;
	}
	
	.footerBox{
		display: inline-block;
		margin-left: 10px;
	}
	
	.tableBox{
		.tableRow{
			display: inline-block;
			.row{
				width: 30px;
				height: 20px;
				line-height: 20px;
				text-align: center;
				display: table-caption;
				border: 1px solid #999;
				border-collapse:collapse;
				overflow: hidden;
			}
			
			.el-input__inner{
			    background-color: #FFF;
				border-radius: 0px !important;
			    border: none !important;
			    box-sizing: border-box;
			    color: #606266;
			    display: inline-block;
			    font-size: inherit;
			    height: 20px !important;
			    line-height: 20px !important;
			    outline: 0;
			    padding: 0 0;
			    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
			    width: 100% !important;
			}
		}
	}
}
</style>
