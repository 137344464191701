export default [
	{
		value:'WOMEN',
		label:'WOMEN',
		children:[
			{
				value:'US',
				label:'US',
				childrens:[
					{
						value:4,
						label:4
					},
					{
						value:4.5,
						label:4.5
					},
					{
						value:5,
						label:5
					},
					{
						value:5.5,
						label:5.5
					},
					{
						value:6,
						label:6
					},
					{
						value:6.5,
						label:6.5
					},
					{
						value:7,
						label:7
					},
					{
						value:7.5,
						label:7.5
					},
					{
						value:8,
						label:8
					},
					{
						value:8.5,
						label:8.5
					},
					{
						value:9,
						label:9
					},
					{
						value:9.5,
						label:9.5
					},
				]
			},
			{
				value:'UK',
				label:'UK',
				childrens:[
					{
						value:1.5,
						label:1.5
					},
					{
						value:2,
						label:2
					},
					{
						value:2.5,
						label:2.5
					},
					{
						value:3,
						label:3
					},
					{
						value:3.5,
						label:3.5
					},
					{
						value:4,
						label:4
					},
					{
						value:4.5,
						label:4.5
					},
					{
						value:5,
						label:5
					},
					{
						value:5.5,
						label:5.5
					},
					{
						value:6,
						label:6
					},
					{
						value:6.5,
						label:6.5
					},
					{
						value:7,
						label:7
					},
				]
			},
			{
				value:'EUR',
				label:'EUR',
				childrens:[
					{
						value:34,
						label:34
					},
					{
						value:35,
						label:35
					},
					{
						value:35.5,
						label:35.5
					},
					{
						value:36,
						label:36
					},
					{
						value:36.5,
						label:36.5
					},
					{
						value:37,
						label:37
					},
					{
						value:38,
						label:38
					},
					{
						value:38.5,
						label:38.5
					},
					{
						value:39,
						label:39
					},
					{
						value:40,
						label:40
					},
					{
						value:40.5,
						label:40.5
					},
					{
						value:41,
						label:41
					},
				]
			},
			{
				value:'CHN',
				label:'CHN',
				childrens:[
					{
						value:21,
						label:21
					},
					{
						value:21.5,
						label:21.5
					},
					{
						value:22,
						label:22
					},
					{
						value:22.5,
						label:22.5
					},
					{
						value:23,
						label:23
					},
					{
						value:23.5,
						label:23.5
					},
					{
						value:24,
						label:24
					},
					{
						value:24.5,
						label:24.5
					},
					{
						value:25,
						label:25
					},
					{
						value:25,
						label:25
					},
					{
						value:25.5,
						label:25.5
					},
				]
			},
			{
				value:'CM',
				label:'CM',
				childrens:[
					{
						value:21.5,
						label:21.5
					},
					{
						value:22,
						label:22
					},
					{
						value:22.5,
						label:22.5
					},
					{
						value:23,
						label:23
					},
					{
						value:23.5,
						label:23.5
					},
					{
						value:24,
						label:24
					},
					{
						value:24,
						label:24
					},
					{
						value:24.5,
						label:24.5
					},
					{
						value:25,
						label:25
					},
					{
						value:25.5,
						label:25.5
					},
					{
						value:26,
						label:26
					},
					{
						value:26.5,
						label:26.5
					},
				]
			}
		]
	},
	{
		value:'MEN',
		label:'MEN',
		children:[
			{
				value:'US',
				label:'US',
				childrens:[
					{
						value:6.5,
						label:6.5
					},
					{
						value:7,
						label:7
					},
					{
						value:7.5,
						label:7.5
					},
					{
						value:8,
						label:8
					},
					{
						value:8.5,
						label:8.5
					},
					{
						value:9,
						label:9
					},
					{
						value:9.5,
						label:9.5
					},
					{
						value:10,
						label:10
					},
					{
						value:10.5,
						label:10.5
					},
					{
						value:11,
						label:11
					},
					{
						value:11.5,
						label:11.5
					},
					{
						value:12,
						label:12
					},
					{
						value:12.5,
						label:12.5
					},
					{
						value:13,
						label:13
					},
					{
						value:13.5,
						label:13.5
					},
				]
			},
			{
				value:'UK',
				label:'UK',
				childrens:[
					{
						value:5.5,
						label:5.5
					},
					{
						value:6,
						label:6
					},
					{
						value:6.5,
						label:6.5
					},
					{
						value:7,
						label:7
					},
					{
						value:7.5,
						label:7.5
					},
					{
						value:8,
						label:8
					},
					{
						value:8.5,
						label:8.5
					},
					{
						value:9,
						label:9
					},
					{
						value:10,
						label:10
					},
					{
						value:6.5,
						label:6.5
					},
					{
						value:10.5,
						label:10.5
					},
					{
						value:11,
						label:11
					},
					{
						value:11.5,
						label:11.5
					},
					{
						value:12,
						label:12
					},
					{
						value:12.5,
						label:12.5
					},
				]
			},
			{
				value:'EUR',
				label:'EUR',
				childrens:[
					{
						value:39,
						label:39
					},
					{
						value:40,
						label:40
					},
					{
						value:40.5,
						label:40.5
					},
					{
						value:41,
						label:41,
					},
					{
						value:42,
						label:42
					},
					{
						value:42.5,
						label:42.5
					},
					{
						value:43,
						label:43
					},
					{
						value:44,
						label:44
					},
					{
						value:44.5,
						label:44.5
					},
					{
						value:45,
						label:45
					},
					{
						value:45.5,
						label:45.5
					},
					{
						value:46,
						label:46
					},
					{
						value:47,
						label:47
					},
					{
						value:47.5,
						label:47.5
					},
					{
						value:48,
						label:48
					},
				]
			},
			{
				value:'CHN',
				label:'CHN',
				childrens:[
					{
						value:24.5,
						label:24.5
					},
					{
						value:25,
						label:25
					},
					{
						value:25,
						label:25
					},
					{
						value:25.5,
						label:25.5,
					},
					{
						value:26,
						label:26
					},
					{
						value:26.5,
						label:26.5
					},
					{
						value:27,
						label:27
					},
					{
						value:27,
						label:27
					},
					{
						value:27.5,
						label:27.5
					},
					{
						value:28,
						label:28
					},
					{
						value:28.5,
						label:28.5
					},
					{
						value:29,
						label:29
					},
					{
						value:29.5,
						label:29.5
					},
					{
						value:30,
						label:30
					},
					{
						value:30.5,
						label:30.5
					},
				]
			},
			{
				value:'CM',
				label:'CM',
				childrens:[
					{
						value:25,
						label:25
					},
					{
						value:25.5,
						label:25.5,
					},
					{
						value:26,
						label:26
					},
					{
						value:26.5,
						label:26.5
					},
					{
						value:27,
						label:27
					},
					{
						value:27,
						label:27
					},
					{
						value:27.5,
						label:27.5
					},
					{
						value:28,
						label:28
					},
					{
						value:28.5,
						label:28.5
					},
					{
						value:29,
						label:29
					},
					{
						value:29.5,
						label:29.5
					},
					{
						value:29.5,
						label:29.5
					},
					{
						value:30,
						label:30
					},
					{
						value:30.5,
						label:30.5
					},
					{
						value:31,
						label:31
					},
				]
			}
		]
	},
	{
		value:'UNISEX',
		label:'UNISEX',
		children:[
			{
				value:'US',
				label:'US',
				childrens:[
					{
						value:2.5,
						label:2.5
					},
					{
						value:3,
						label:3
					},
					{
						value:3.5,
						label:3.5
					},
					{
						value:4,
						label:4
					},
					{
						value:4.5,
						label:4.5
					},
					{
						value:5,
						label:5
					},
					{
						value:5.5,
						label:5.5
					},
					{
						value:6,
						label:6
					},
					{
						value:6.5,
						label:6.5
					},
					{
						value:7,
						label:7
					},
					{
						value:7.5,
						label:7.5
					},
					{
						value:8,
						label:8
					},
					{
						value:8.5,
						label:8.5
					},
					{
						value:9,
						label:9
					},
					{
						value:9.5,
						label:9.5
					},
					{
						value:10,
						label:10
					},
					{
						value:10.5,
						label:10.5
					},
					{
						value:11,
						label:11
					},
					{
						value:11.5,
						label:11.5
					},
					{
						value:12,
						label:12
					},
					{
						value:12.5,
						label:12.5
					},
					{
						value:13,
						label:13
					},
					{
						value:13.5,
						label:13.5
					},
				]
			},
			{
				value:'UK',
				label:'UK',
				childrens:[
					{
						value:1.5,
						label:1.5
					},
					{
						value:2,
						label:2
					},
					{
						value:2.5,
						label:2.5
					},
					{
						value:3,
						label:3
					},
					{
						value:3.5,
						label:3.5
					},
					{
						value:4,
						label:4
					},
					{
						value:4.5,
						label:4.5
					},
					{
						value:5,
						label:5
					},
					{
						value:5.5,
						label:5.5
					},
					{
						value:6,
						label:6
					},
					{
						value:6.5,
						label:6.5
					},
					{
						value:7,
						label:7
					},
					{
						value:7.5,
						label:7.5
					},
					{
						value:8,
						label:8
					},
					{
						value:8.5,
						label:8.5
					},
					{
						value:9,
						label:9
					},
					{
						value:10,
						label:10
					},
					{
						value:6.5,
						label:6.5
					},
					{
						value:10.5,
						label:10.5
					},
					{
						value:11,
						label:11
					},
					{
						value:11.5,
						label:11.5
					},
					{
						value:12,
						label:12
					},
					{
						value:12.5,
						label:12.5
					},
				]
			},
			{
				value:'EUR',
				label:'EUR',
				childrens:[
					{
						value:34,
						label:34
					},
					{
						value:35,
						label:35
					},
					{
						value:35.5,
						label:35.5
					},
					{
						value:36,
						label:36
					},
					{
						value:36.5,
						label:36.5
					},
					{
						value:37,
						label:37
					},
					{
						value:38,
						label:38
					},
					{
						value:38.5,
						label:38.5
					},
					{
						value:39,
						label:39
					},
					{
						value:40,
						label:40
					},
					{
						value:40.5,
						label:40.5
					},
					{
						value:41,
						label:41
					},
					{
						value:42,
						label:42
					},
					{
						value:42.5,
						label:42.5
					},
					{
						value:43,
						label:43
					},
					{
						value:44,
						label:44
					},
					{
						value:44.5,
						label:44.5
					},
					{
						value:45,
						label:45
					},
					{
						value:45.5,
						label:45.5
					},
					{
						value:46,
						label:46
					},
					{
						value:47,
						label:47
					},
					{
						value:47.5,
						label:47.5
					},
					{
						value:48,
						label:48
					},
				]
			},
			{
				value:'CHN',
				label:'CHN',
				childrens:[
					{
						value:21,
						label:21
					},
					{
						value:21.5,
						label:21.5
					},
					{
						value:22,
						label:22
					},
					{
						value:22.5,
						label:22.5
					},
					{
						value:23,
						label:23
					},
					{
						value:23.5,
						label:23.5
					},
					{
						value:24,
						label:24
					},
					{
						value:24.5,
						label:24.5
					},
					{
						value:25,
						label:25
					},
					{
						value:25,
						label:25
					},
					{
						value:25.5,
						label:25.5
					},
					{
						value:26,
						label:26
					},
					{
						value:26.5,
						label:26.5
					},
					{
						value:27,
						label:27
					},
					{
						value:27,
						label:27
					},
					{
						value:27.5,
						label:27.5
					},
					{
						value:28,
						label:28
					},
					{
						value:28.5,
						label:28.5
					},
					{
						value:29,
						label:29
					},
					{
						value:29.5,
						label:29.5
					},
					{
						value:30,
						label:30
					},
					{
						value:30.5,
						label:30.5
					},
				]
			},
			{
				value:'CM',
				label:'CM',
				childrens:[
					{
						value:21.5,
						label:21.5
					},
					{
						value:22,
						label:22
					},
					{
						value:22.5,
						label:22.5
					},
					{
						value:23,
						label:23
					},
					{
						value:23.5,
						label:23.5
					},
					{
						value:24,
						label:24
					},
					{
						value:24,
						label:24
					},
					{
						value:24.5,
						label:24.5
					},
					{
						value:25,
						label:25
					},
					{
						value:25.5,
						label:25.5
					},
					{
						value:26,
						label:26
					},
					{
						value:26.5,
						label:26.5
					},
					{
						value:27,
						label:27
					},
					{
						value:27,
						label:27
					},
					{
						value:27.5,
						label:27.5
					},
					{
						value:28,
						label:28
					},
					{
						value:28.5,
						label:28.5
					},
					{
						value:29,
						label:29
					},
					{
						value:29.5,
						label:29.5
					},
					{
						value:29.5,
						label:29.5
					},
					{
						value:30,
						label:30
					},
					{
						value:30.5,
						label:30.5
					},
					{
						value:31,
						label:31
					},
				]
			}
		]
	}
]